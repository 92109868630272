<template>
  <div class="login-container">
    <div class="left"></div>
    <div class="right">
      <!-- <div class="logo-img">
        <div class="left-logo"><img src="" alt="" /></div>
        <div class="right-txt"><img src="~@/assets/ykw.png" alt="" /></div>
      </div> -->
      <div class="ykw">管理后台</div>
      <!-- <div class="ykw-en">zuji manage</div> -->
      <div class="login-form">
        <el-form ref="form" :model="formData" label-width="0" :rules="rules">
          <el-form-item prop="account">
            <div class="tip-icon">
              <img style="width:16px" src="~@/assets/icon/phone_icon.png" alt="" />
              <span>账号</span>
            </div>
            <el-input v-model="formData.mobile" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <!-- <el-form-item prop="password">
            <div class="tip-icon">
              <img style="width:16px" src="~@/assets/icon/password_icon.png" alt="" />
              <span>密码</span>
            </div>
            <el-input type="password" v-model="formData.password" placeholder="请输入密码"></el-input>
          </el-form-item> -->
          <!-- <el-form-item prop="yzm">
            <div class="tip-icon">
              <img style="width:16px" src="~@/assets/icon/yanzheng_icon.png" alt="" />
              <span>验证码</span>
            </div>
            <el-input type="text" style="width: 50%;margin-right:10px" v-model="formData.code" placeholder="验证码"></el-input>
            <img class="yzm-img" :src="'data:image/png;base64,' + imgCode" @click="changeYzm" />
          </el-form-item> -->
          <el-form-item>
            <div class="tip-icon">
              <img style="width: 16px" src="~@/assets/icon/yanzheng_icon.png" alt="" />
              <span>验证码</span>
            </div>
            <el-input class="yan-input" v-model="formData.code" placeholder="请输入验证码">
              <template slot="append">
                <el-link :type="isDisabled ? 'info' : 'primary'" :disabled="isDisabled" :underline="false" @click="getCode" class="yan_code">{{
                  codeStr
                }}</el-link>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <div class="login-btn">
              <el-button type="primary" @click="onSubmit">登录</el-button>
            </div>
            <!-- <div class="reset-btn">
              <el-link type="info" :underline="false">验证码登录</el-link>
              <el-link type="info" :underline="false" @click="$router.push({ path: '/resetpassword' })">忘记密码</el-link>
            </div> -->
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { nanoid } from 'nanoid'
import { getLoginCodeAPI } from './api'
export default {
  name: 'Login',

  data() {
    return {
      imgCode: '',
      formData: {
        // rcKey: nanoid(),
        code: '',
        mobile: '',
        // password: ''
      },
      rules: {
        // account: [
        //   { required: true, message: '账号不能为空', trigger: 'blur' },
        //   { pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/, message: '手机号码错误', trigger: 'blur' }
        // ],
        // password: [
        //   { required: true, message: '密码不能为空', trigger: 'blur' },
        //   { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,}$/, message: '密码错误，密码由数字+字母，长度大于8位', trigger: 'blur' }
        // ]
      },
      codeStr: '获取验证码'
    }
  },

  mounted() {
    // this.getYzmUrl()
  },

  methods: {
    onSubmit() {
      this.$store.dispatch('Login', this.formData).then(() => {
        this.$router.push({ path: '/home' })
        sessionStorage.removeItem('keyPath')
      })
    },

    changeYzm() {
      this.formData.rcKey = nanoid()
      this.getYzmUrl()
    },

    // async getYzmUrl() {
    //   let res = await getImgCodeAPI(this.formData.rcKey)
    //   this.imgCode = res.imgCode
    // },
     async getCode() {
      if(!this.formData.mobile) return this.$message.error('手机号必填')
      await getLoginCodeAPI(this.formData.mobile)
      this.isDisabled = true
      let sec = 59
      this.codeStr = `重发（${sec}s）`
      this.timer = setInterval(() => {
        sec = sec - 1
        this.codeStr = `重发（${sec}s）`
        if (sec <= 0) {
          this.codeStr = '获取验证码'
          this.isDisabled = false
          clearInterval(this.timer)
        }
      }, 1000)
    },
  }
}
</script>

<style lang="scss" scoped>
.login-container {
  // padding: 12px;
  display: flex;
  height: 100vh;
  .left {
    min-width: 500px;
    flex: 1;
    background: url('/bg.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }
  .right {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 0.4;
    min-width: 600px;
    padding: 72px;
    .logo-img {
      display: flex;
      align-items: center;
      margin-bottom: 32px;
      // margin-top:20px;
      .left-logo {
        margin-right: 12px;
        width: 256px;
        height: 102px;
        img {
          width: 256px;
          margin-left: -40px;
        }
      }
      .right-txt {
        width: 78px;
        height: 24px;
        img {
          width: 78px;
          height: 24px;
        }
      }
    }
    .ykw {
      font-weight: 600;
      font-size: 24px;
      color: #1a1e25;
      line-height: 32px;
      margin-bottom: 20px;
    }
    .ykw-en {
      line-height: 32px;
      font-weight: 500;
      font-size: 16px;
      color: #788692;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .login-form {
      .tip-icon {
        display: flex;
        align-items: center;
        line-height: 30px;
        color: #1a1e25;
        font-size: 14px;
        img {
          margin-right: 8px;
        }
      }
      .yzm-img {
        height: 48px;
      }
      .yan-input /deep/{
        .el-input__inner {
          border-right: none;
        }
        .el-input-group__append {
          border-color: #1a1e25;
          background-color: #fff;
          border-left: none;
        }
        .yan_code {
          font-size: 16px;
          border-left: 1px solid #ccc;
          padding-left: 20px;
        }
      }
      .el-form /deep/ {
        .el-input__inner {
          border-color: #1a1e25;
          height: 48px;
        }
        .login-btn {
          display: flex;
          margin-top: 40px;
          .el-button {
            flex: 1;
            height: 48px;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            background-color: #1b6fee;
            border-color: #1b6fee;
          }
        }
        .reset-btn {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 12px;
        }
      }
    }
  }
}
</style>
